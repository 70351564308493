import { useContext, useEffect, useState } from 'react';

import { get } from './Agent';

import Loader from './components/Loader';

// router provider
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// context
import { Context } from './Context';

// components
import Home from './components/Home';
import ButtonsFloat from './components/ButtonsFloat';
import Sidebar from './components/Sidebar';
import View360 from './components/View360';
import Vive from './components/Vive';
import ZonasSociales from './components/ZonasSociales';
import Plantas from './components/Plantas';
import Tipologias from './components/Tipologias';
import Contacto from './components/Contacto';

// assets
import turn from './assets/turn.svg';

// style
import './App.css';
import Modal from './components/Modal';

const App = () => {
  const { getInfoFromData, setData, data, setLoading, openImageModal } = useContext(Context);
  const [isLandscape, setIsLandscape] = useState(false);

  useEffect(() => {
    console.log('App.js');
    checkLandscape();
    setLoading(true);
    get('landing/paradise')
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => console.error(error));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const popup_image = getInfoFromData('popup_image');
    if (popup_image && popup_image !== '') {
      setTimeout(() => {
        openImageModal(popup_image, true, true);
      }, 3000);
    }
    // eslint-disable-next-line
  }, [data]);

  const checkLandscape = () => {
    if (window.innerWidth > window.innerHeight) {
      setIsLandscape(true);
    } else {
      setIsLandscape(false);
    }
  };

  // call this function when the window is resized
  window.addEventListener('resize', checkLandscape);

  return data && data?.length > 0 ? (
    <BrowserRouter>
      {!isLandscape && (
        <div className='landscape'>
          <img src={turn} alt='turn' /> Por favor, gira tu dispositivo.
        </div>
      )}
      <div className='sidebar'>
        <Sidebar items={getInfoFromData('menu')} />
      </div>
      <div className='content'>
        <Routes>
          <Route path='/' element={<View360 />} />
          <Route path='/render' element={<Home />} />
          <Route path='/vive-en-cielo-mar' element={<Vive />} />
          <Route path='/zonas-sociales' element={<ZonasSociales />} />
          <Route path='/planta' element={<Plantas />} />
          <Route path='/tipologias' element={<Tipologias />} />
          <Route path='/contacto' element={<Contacto />} />
        </Routes>
        <ButtonsFloat />
      </div>
      <Loader />
      <Modal />
    </BrowserRouter>
  ) : (
    <Loader />
  );
};

export default App;
