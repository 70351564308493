import React, { createContext, useState } from 'react';

const Context = createContext();

const ContextProvider = ({ children }) => {
  const [data, setData] = useState([]);

  const [settings, setSettings] = useState({
    lang: 'es',
    langSelector: false,
    modal: false,
    modalContent: '',
    modalStyles: {},
  });

  const [loading, setLoading] = useState(false);

  const getInfoFromData = (key) => {
    const res = data?.filter((item) => item.meta_key === key)[0];
    let datos;
    const meta_type = res?.meta_type;
    switch (meta_type) {
      case 'json':
        try {
          datos = JSON.parse(res.meta_value);
        } catch (error) {
          datos = [];
          console.error(error);
        }
        break;
      case 'array':
        try {
          datos = JSON.parse(res.meta_value);
        } catch (error) {
          datos = [];
          console.error(error);
        }
        break;
      case 'text':
        datos = res.meta_value;
        break;
      default:
        datos = [];
    }

    return datos;
  };

  const getInfoFromDataByLang = (key) => {
    const res = data?.filter((item) => item.meta_key === key)[0];
    let datos;
    const meta_type = res?.meta_type;
    switch (meta_type) {
      case 'json':
        try {
          datos = JSON.parse(res.meta_value);
        } catch (error) {
          datos = [];
          console.error(error);
        }
        break;
      case 'text':
        datos = res.meta_value;
        break;
      default:
        datos = [];
    }
    return datos[settings.lang];
  };

  const openImageModal = (image, fromPublic, modalTransparent) => {
    fromPublic = fromPublic || false;
    setSettings({
      ...settings,
      modal: true,
      modalTransparent: modalTransparent || false,
      modalContent: (
        <div className='modal__content__image'>
          {fromPublic && image && image !== '' ? (
            <img src={image} alt='popup-home' className='popup' />
          ) : (
            <img src={require('./assets/' + image)} alt='full' />
          )}
        </div>
      ),
      modalStyles: {
        modalContent: {
          height: '100%',
        },
        modalCloseButton: {
          backgroundColor: '#fff',
          color: '#606060',
          border: '1px solid #606060',
          borderRadius: '50%',
          width: '30px',
          height: '30px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: 300,
          position: 'absolute',
          top: 10,
          left: 0,
          right: 0,
          margin: 'auto',
        },
      },
    });
  };

  return (
    <Context.Provider
      value={{
        data,
        setData,
        getInfoFromData,
        settings,
        setSettings,
        getInfoFromDataByLang,
        loading,
        setLoading,
        openImageModal,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { Context, ContextProvider };
