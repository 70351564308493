import { useContext } from 'react';
import { Context } from '../Context';

// assets
import torres from '../assets/torres.png';
import pisos from '../assets/pisos.png';
import apartamentos from '../assets/apartamentos.png';
import logo from '../assets/logo.png';

// components
import Text from '../helpers/Text';

const Vive = () => {
  const { settings, getInfoFromData, getInfoFromDataByLang } = useContext(Context);

  return (
    <div className='vive'>
      <div className='vive-container'>
        <div className='vive-logo'>
          <img src={logo} alt='Logo' className='logo-white' />
        </div>
        <div className='vive-title'>
          <h1>
            <Text>{getInfoFromData('vive_title')}</Text>
          </h1>
        </div>
        <div className='vive-content' dangerouslySetInnerHTML={{ __html: getInfoFromDataByLang('vive_text') }}></div>
        <div className='vive-icons'>
          <div className='vive-icon'>
            <img src={torres} alt='Torres' />
            <div className='number'>{getInfoFromData('torres')}</div>
            <div className='text'>{settings.lang === 'es' ? 'Torres en el proyecto' : 'Towers in the project'}</div>
          </div>
          <div className='vive-icon'>
            <img src={pisos} alt='Pisos' />
            <div className='number'>{getInfoFromData('pisos')}</div>
            <div className='text'>{settings.lang === 'es' ? 'Pisos por torre' : 'Floors per tower'}</div>
          </div>
          <div className='vive-icon'>
            <img src={apartamentos} alt='Apartamentos' />
            <div className='number'>{getInfoFromData('apartamentos')}</div>
            <div className='text'>{settings.lang === 'es' ? 'Apartamentos por torre' : 'Apartments per tower'}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vive;
