import { useEffect, useState } from 'react';

const ZonasSociales = () => {
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [images, setImages] = useState(null);
  // require images in assets/zonas-sociales/zona-social-${i}.png from 1 to 17

  const icons = [...Array(14).keys()].map((i) => {
    return require(`../assets/zonas-sociales/iconos-web-${i + 1}.png`);
  });

  const selectZona = (i) => {
    setBackgroundImage(images[i]);
  };

  useEffect(() => {
    const imagesArr = [...Array(14).keys()].map((i) => {
      if (i === 4) return null;
      return require(`../assets/zonas-sociales/zonas-sociales-${i + 1}.jpg`);
    });
    setImages(imagesArr);
  }, []);

  useEffect(() => {
    if (images) setBackgroundImage(images[0]);
  }, [images]);

  return backgroundImage ? (
    <div className='zonas-sociales'>
      <div className='zonas-sociales__background'>
        <img src={backgroundImage} alt='background' />
      </div>
      <div className='zonas-sociales__menu'>
        {icons.map((icon, i) => {
          if (i === 4) return null;
          return (
            <div className='zonas-sociales__menu_icon' key={i} onClick={() => selectZona(i)}>
              <img src={icon} alt={`icon-${i}`} width='50px' />
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};

export default ZonasSociales;
