import { useState, useEffect } from 'react';

const LoadSVG = (props) => {
  const { svg } = props;
  const [svgContent, setSvgContent] = useState(null);

  useEffect(() => {
    fetch(svg)
      .then((response) => response.text())
      .then(
        (
          text // return everything but svg tag and their attributes
        ) => setSvgContent(text.substring(text.indexOf('<svg'), text.indexOf('</svg>') + 6))
      );
  }, [svg]);

  if (!svgContent) {
    return null;
  }
  return svgContent;
};

export default LoadSVG;
