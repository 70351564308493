import { useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faWaze, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faDownload as fasDownload } from '@fortawesome/free-solid-svg-icons';

import { Context } from '../Context';

const Redes = () => {
  const { getInfoFromData } = useContext(Context);
  return (
    <div className='sidebar_redes'>
      <div className='sidebar_redes__rowitem'>
        <div className='sidebar_redes__item'>
          <a href={getInfoFromData('instagram')} target='_blank' rel='noreferrer'>
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
        <div className='sidebar_redes__item'>
          <a href={getInfoFromData('waze')} target='_blank' rel='noreferrer'>
            <FontAwesomeIcon icon={faWaze} />
          </a>
        </div>
      </div>
      <div className='sidebar_redes__rowitem'>
        <div className='sidebar_redes__item'>
          <a href={getInfoFromData('facebook')} target='_blank' rel='noreferrer'>
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
        </div>
        <div className='sidebar_redes__item'>
          <a href={getInfoFromData('brochure')} target='_blank' rel='noreferrer'>
            <FontAwesomeIcon icon={fasDownload} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Redes;
