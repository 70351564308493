import { useContext } from 'react';

// navigation
import { useNavigate } from 'react-router-dom';

// context
import { Context } from '../Context';

// helpers
import Text from '../helpers/Text';
import LoadSVG from '../helpers/LoadSVG';

// components
import Redes from './Redes';

//assets
import logo from '../assets/logo.png';

const Sidebar = ({ items }) => {
  const { settings, setSettings } = useContext(Context);
  const navigate = useNavigate();

  return (
    <div className='sidebar-content'>
      <div className='sidebar-content__lang'>
        {settings.langSelector ? (
          <>
            <div
              className='sidebar-content__flag'
              onClick={() => {
                setSettings({ ...settings, lang: 'es' });
              }}
            >
              es
            </div>
            <div
              className='sidebar-content__flag'
              onClick={() => {
                setSettings({ ...settings, lang: 'en' });
              }}
            >
              en
            </div>
          </>
        ) : null}
      </div>
      <div className='sidebar-content__logo'>
        <img
          src={logo}
          alt='logo'
          className='logo-white'
          onClick={() => {
            navigate('/render');
          }}
        />
      </div>
      <ul className='sidebar-content__menu'>
        {items?.map((item, index) => {
          const iconItem = LoadSVG({ svg: 'images/' + item.icon });
          if (item.visible === false) return null;
          return (
            <li
              key={'item' + index}
              onClick={() => {
                navigate(item.link);
              }}
            >
              <div className='sidebar_item__icon' dangerouslySetInnerHTML={{ __html: iconItem }}></div>
              <Text>{item.label}</Text>
            </li>
          );
        })}
      </ul>
      <Redes />
    </div>
  );
};

export default Sidebar;
