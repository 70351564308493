// context
import { useContext } from 'react';
import { Context } from '../Context';

// assets
import cotizar from '../assets/cotizar.png';
import whatsapp from '../assets/whatsapp.png';
import Form from './Form';
import Modal from './Modal';

const ButtonsFloat = () => {
  const { settings, setSettings, getInfoFromData } = useContext(Context);

  const styles = {
    modalContent: {
      width: '450px',
      maxWidth: '80%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '25px',
      backgroundColor: '#035DA3',
      padding: '20px',
      margin: '20px auto 0',
    },
    modalCloseButton: {
      color: '#fff',
      border: '1px solid #fff',
      borderRadius: '50%',
      width: '20px',
      height: '20px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 300,
      margin: 'auto',
      marginBottom: '5px',
    },
  };
  return (
    <>
      <Modal />
      <div
        className='button-float top'
        onClick={() => {
          setSettings({
            ...settings,
            modal: true,
            modalContent: <Form />,
            modalStyles: styles,
          });
        }}
      >
        <img src={cotizar} alt='cotizar' />
      </div>
      <div className='button-float bottom'>
        <a href={getInfoFromData('whatsapp')} target='_blank' rel='noreferrer'>
          <img src={whatsapp} alt='whatsapp' />
        </a>
      </div>
    </>
  );
};

export default ButtonsFloat;
