import { useState } from 'react';

const Tabs = ({ tabList }) => {
  const [activeTab, setActiveTab] = useState(tabList[0].id);

  const handleTabClick = (id) => {
    setActiveTab(id);
  };

  return (
    <div className='tabs'>
      <ul className='tabs__list'>
        {tabList.map((tab) => (
          <li
            key={tab.id}
            className={`tabs__list__item ${activeTab === tab.id ? 'active' : ''}`}
            onClick={() => handleTabClick(tab.id)}
          >
            {tab.title}
          </li>
        ))}
      </ul>
      <div className='tabs__content'>
        {tabList.map((tab) => (
          <div key={tab.id} className={`tabs__content__item ${activeTab === tab.id ? 'active' : ''}`}>
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
