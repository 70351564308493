import { useContext, useState } from 'react';

import { Context } from '../Context';

import { post } from '../Agent';

const Form = () => {
  const { settings, getInfoFromData } = useContext(Context);

  const [form, setForm] = useState({
    nombre: '',
    correo: '',
    celular: '',
  });
  const [error, setError] = useState(null);

  const send = () => {
    setError(null);
    if (form.nombre === '' || form.correo === '' || form.celular === '') {
      const messageError = settings.lang === 'es' ? 'Todos los campos son obligatorios' : 'All fields are required';
      setError(messageError);
      return;
    }
    if (!validateEmail(form.correo)) {
      const messageError = settings.lang === 'es' ? 'Correo inválido' : 'Invalid email';
      setError(messageError);
      return;
    }

    if (form.celular.length < 10 && isNaN(form.celular)) {
      const messageError =
        settings.lang === 'es'
          ? 'Celular inválido, por favor ingrese solo números'
          : 'Invalid phone number, please enter only numbers';
      setError(messageError);
      return;
    }

    const message = settings.lang === 'es' ? 'Enviando...' : 'Sending...';

    setError(message);

    const data = {
      nombre: form.nombre,
      correo: form.correo,
      celular: form.celular,
      landing: 'paradise',
      subject: 'Mensaje de contacto Landing Paradise Cartagena',
    };

    post('send-mail', data)
      .then((res) => {
        try {
          const resp = JSON.parse(res.data);
          const messageSuccess = resp[settings.lang];
          setError(messageSuccess);
          setForm({
            nombre: '',
            correo: '',
            celular: '',
          });
        } catch (error) {
          const messageError = settings.lang === 'es' ? 'Error al enviar el mensaje' : 'Error sending the message';
          setError(messageError);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;

    return re.test(email);
  };

  return (
    <div className='form-container'>
      <div className='form-title'>
        <div className='form-title_top'>
          {settings.lang === 'es' ? (
            <span>
              APARTAMENTOS
              <br />
              DESDE:
            </span>
          ) : (
            <span>
              APARTMENTS
              <br />
              FROM:
            </span>
          )}
        </div>
        <div className='form-price'>{getInfoFromData('contacto_price')}</div>
        <div className='form-title_bottom'>
          {settings.lang === 'es' ? <span>MILLONES</span> : <span>MILLONES</span>}
        </div>
      </div>
      <div className='form'>
        <div className='form-label'>
          {settings.lang === 'es' ? <span>¡TOMA UNA ASESORIA!</span> : <span>¡TAKE AN ADVICE!</span>}
        </div>
        <div className='form-inputs'>
          <div className='form-input'>
            <input
              type='text'
              placeholder={settings.lang === 'es' ? 'Nombre*' : 'Name*'}
              value={form.nombre}
              onChange={(e) => setForm({ ...form, nombre: e.target.value })}
            />
          </div>
          <div className='form-input'>
            <input
              type='text'
              placeholder={settings.lang === 'es' ? 'Correo*' : 'Email*'}
              value={form.correo}
              onChange={(e) => setForm({ ...form, correo: e.target.value })}
            />
          </div>
          <div className='form-input'>
            <input
              type='text'
              placeholder={settings.lang === 'es' ? 'Celular*' : 'Cellphone*'}
              value={form.celular}
              onChange={(e) => setForm({ ...form, celular: e.target.value })}
            />
          </div>
          <div className='form-input'>{error && <span style={{ color: '#e9b94a' }}>{error}</span>}</div>
          <div className='form-input d-flex flex-center'>
            <button className='button-primary' onClick={() => send()}>
              {settings.lang === 'es' ? <span>Enviar</span> : <span>Send</span>}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
