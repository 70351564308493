import { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import LoadSVG from '../helpers/LoadSVG';

import { useNavigate } from 'react-router-dom';

const MenuFloat = ({ current }) => {
  const [activeHome, setActiveHome] = useState(false);
  const [active360, setActive360] = useState(false);

  useEffect(() => {
    if (current === 'home') {
      setActiveHome(true);
      setActive360(false);
    } else if (current === 'view-360') {
      setActiveHome(false);
      setActive360(true);
    }
  }, [current]);

  const navigate = useNavigate();

  return (
    <div className='map-menu'>
      <div
        className={'map-menu__item' + (activeHome ? ' active' : '')}
        onClick={() => {
          setActiveHome(true);
          setActive360(false);
          navigate('/render');
        }}
      >
        <FontAwesomeIcon icon={faMapMarkerAlt} />
      </div>
      <div
        className={'map-menu__item' + (active360 ? ' active' : '')}
        onClick={() => {
          setActiveHome(false);
          setActive360(true);
          navigate('/');
        }}
      >
        <div className='icon360' dangerouslySetInnerHTML={{ __html: LoadSVG({ svg: 'images/icon-360.svg' }) }}></div>
      </div>
    </div>
  );
};

export default MenuFloat;
