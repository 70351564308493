import { useContext } from 'react';

// context
import { Context } from '../Context';

import Tabs from './Tabs';
import Modal from './Modal';

const Tipologias = () => {
  const { getInfoFromData, openImageModal } = useContext(Context);

  const tabContentTemplate = (tabData) => {
    return tabData ? (
      <div className='tab-content'>
        <div className='tab-content__image'>
          <img src={require(`../assets/tipologias/${tabData.imageMain}`)} alt={tabData.type} />
        </div>
        <div className='tab-content__text'>
          <div className='tab_content__price'>{tabData.price}</div>
          <div className='tab_content__separator'></div>
          <div className='tab_content__areas'>
            <div className='tab_content__area'>
              <div className='tab_content__area__title'>ÁREA CONSTRUIDA</div>
              <div className='tab_content__area__value' dangerouslySetInnerHTML={{ __html: tabData.areaBuilt }}></div>
            </div>
            <div className='tab_content__area'>
              <div className='tab_content__area__title'>ÁREA PRIVADA</div>
              <div className='tab_content__area__value' dangerouslySetInnerHTML={{ __html: tabData.areaPrivate }}></div>
            </div>
          </div>
          <div className='tab_content__separator'></div>
          <div className='tab_content__amenities__and__extensions'>
            <div className='tab_content__amenidades'>
              {tabData.amenities && tabData.amenities.length
                ? tabData.amenities.map((amenity, index) => (
                    <div className='tab_content__amenidad' key={index}>
                      {amenity}
                    </div>
                  ))
                : ''}
            </div>
            <div className='tab_content__extensiones'>
              {tabData.extensions && tabData.extensions.length
                ? tabData.extensions.map((extension, index) => (
                    <div
                      className='tab_content__extension'
                      dangerouslySetInnerHTML={{ __html: extension }}
                      key={index}
                    ></div>
                  ))
                : ''}
            </div>
          </div>

          <div className='tab_content__plain'>
            <div className='tab_content__plain__zoom__icon'>
              <img
                src={require('../assets/zoom.png')}
                alt='Zoom'
                onClick={() => {
                  tabData.imagePlain && openImageModal('tipologias/' + tabData.imagePlain);
                }}
              />
            </div>
            <img
              src={require(`../assets/tipologias/${tabData.imagePlain}`)}
              alt={tabData.type}
              onClick={() => {
                tabData.imagePlain && openImageModal('tipologias/' + tabData.imagePlain);
              }}
            />
          </div>
        </div>
      </div>
    ) : (
      ''
    );
  };

  const apartments = getInfoFromData('apartments');

  const tabList = [
    {
      id: 1,
      title: 'TIPO A',
      content: tabContentTemplate(apartments?.[0]),
    },
    {
      id: 2,
      title: 'TIPO B',
      content: tabContentTemplate(apartments?.[1]),
    },
    {
      id: 3,
      title: 'TIPO C',
      content: tabContentTemplate(apartments?.[2]),
    },
    {
      id: 4,
      title: 'TIPO D',
      content: tabContentTemplate(apartments?.[3]),
    },
  ];

  return (
    <div id='section-tipologias'>
      <h1>ELIGE EL APARTAMENTO QUE MÁS TE GUSTE</h1>
      <p className='tipologias-subtitle'>
        Apartamentos desde <span dangerouslySetInnerHTML={{ __html: getInfoFromData('desde') }}></span> hasta{' '}
        <span dangerouslySetInnerHTML={{ __html: getInfoFromData('hasta') }}></span> - Área construida
      </p>
      <Tabs tabList={tabList} />
      <Modal />
    </div>
  );
};

export default Tipologias;
