const GetEnv = (key, defaultValue = null) => {
  const value = process.env['REACT_APP_' + key];
  if (value === undefined) {
    if (defaultValue === null) {
      throw new Error(`Environment variable ${key} is missing`);
    }
    return defaultValue;
  }
  return value;
};

export default GetEnv;
