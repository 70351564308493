import { useState, useContext } from 'react';

import { Context } from '../Context';

const Plantas = () => {
  const { openImageModal } = useContext(Context);

  const [etapaUno, setEtapaUno] = useState(false);
  const [etapaDos, setEtapaDos] = useState(false);

  const BtnBack = () => {
    return (
      <div
        className='btn-back'
        onClick={() => {
          setEtapaUno(false);
          setEtapaDos(false);
        }}
      >
        <img src={require('../assets/backbtn.png')} alt='back' />
      </div>
    );
  };

  return (
    <div id='planta-seccion'>
      <button
        className='btn-plantas uno'
        onClick={() => {
          setEtapaUno(true);
          setEtapaDos(false);
        }}
      >
        ETAPA 1
      </button>

      <button
        className='btn-plantas dos'
        onClick={() => {
          setEtapaDos(true);
          setEtapaUno(false);
        }}
      >
        ETAPA 2
      </button>
      <img src={require('../assets/etapas.jpg')} alt='etapas' />
      {etapaUno ? (
        <div id='etapa-uno' className='etapas'>
          <div className='etapa-content'>
            <BtnBack />
            <div className='etapa-title'>
              ZONAS SOCIALES <b>ETAPA UNO</b>
            </div>
            <div className='etapa-text'>
              <div className='etapa-list-left'>
                <ul>
                  <li>HALL CAR</li>
                  <li>LOBBY</li>
                  <li>ZONA HÚMEDA DE NIÑOS: PISCINA, ZONA DE PLAYA, TERRAZA PADRES Y SOLARIUM </li>
                  <li>SALÓN DE EVENTOS</li>
                  <li>ZONA SPA: SALA DE ESPERA, SAUNA Y TURCO</li>
                </ul>
              </div>
              <div className='etapa-list-right'>
                <ul>
                  <li>ZONA JACUZZI</li>
                  <li>ZONA B.B.Q.</li>
                  <li>CINEMA</li>
                  <li>ZONA EN VIVO</li>
                  <li>COOWORKING/MEETING ROOM</li>
                  <li>ZONA DE JUEGOS</li>
                  <li>PARQUE INFANTIL</li>
                  <li>CANCHA SINTÉTICA </li>
                </ul>
              </div>
            </div>
            <img
              src={require('../assets/thumbnail-etapauno.png')}
              alt='etapa-uno'
              className='fullwidth'
              onClick={() => openImageModal('etapa1.png')}
            />
          </div>
        </div>
      ) : null}
      {etapaDos ? (
        <div id='etapa-dos' className='etapas'>
          <div className='etapa-content'>
            <BtnBack />
            <div className='etapa-title'>
              ZONAS SOCIALES <b>ETAPA DOS</b>
            </div>
            <div className='etapa-text'>
              <div className='etapa-list-left'>
                <ul>
                  <li>GIMNASIO: PILATES, YOGA, SPINING, ZONA DE MAQUINAS, ZONA DE CAMINADORAS Y TRX. </li>
                  <li>BAR LOUNGE</li>
                </ul>
              </div>
              <div className='etapa-list-right'>
                <ul>
                  <li>ZONA HÚMEDA DE ADULTOS: PISCINA, ZONA DE PLAYA Y SOLARIUM. </li>
                  <li>PARQUE CANINO </li>
                  <li>CANCHA MULTIFUNCIONAL</li>
                </ul>
              </div>
            </div>
            <img
              src={require('../assets/thumbnail-etapados.png')}
              alt='etapa-uno'
              className='fullwidth'
              onClick={() => openImageModal('etapa2.png')}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Plantas;
