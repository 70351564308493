// context
import { useContext } from 'react';
import { Context } from '../Context';

const Text = ({ children }) => {
  const { settings } = useContext(Context);
  return children[settings.lang];
};

export default Text;
