import MenuFloat from './MenuFloat';

const Home = () => {
  return (
    <div className='home'>
      <MenuFloat current={'home'} />
    </div>
  );
};

export default Home;
