import { useContext } from 'react';
import { Context } from '../Context';

const Modal = () => {
  const { settings, setSettings } = useContext(Context);

  const onClose = () => {
    setSettings({
      ...settings,
      modal: false,
      modalContent: '',
      modalStyles: {},
      modalTransparent: false,
    });
  };

  return settings.modal ? (
    <div
      className='modal '
      style={{ background: settings?.modalTransparent ? 'transparent' : 'rgba(255,255,255,0.8)' }}
    >
      <div className='modal-content' style={settings?.modalStyles?.modalContent}>
        <div className='modal-close-button' onClick={onClose} style={settings?.modalStyles?.modalCloseButton}>
          X
        </div>
        {settings.modalContent}
      </div>
    </div>
  ) : null;
};

export default Modal;
