import { useMemo, useContext, useEffect } from 'react';

import { Context } from '../Context';

import MenuFloat from './MenuFloat';

const View360 = () => {
  const { setLoading } = useContext(Context);

  useEffect(() => {
    setLoading(true);
    // eslint-disable-next-line
  }, []);
  const fetchIframeContent = useMemo(() => {
    return (
      <iframe
        src='https://www.google.com/maps/embed?pb=!4v1674195163696!6m8!1m7!1sCAoSLEFGMVFpcE16aG5RalpQQ1lnQy15UEl0dVM4RGh0dWVMOEFJbzJSRXZ5cTlC!2m2!1d10.4578484!2d-75.508341!3f37.59679947147142!4f-16.311378787697706!5f0.4003161831622405'
        style={{ maxWidth: '100%', maxHeight: 2160, width: '100%', height: '100%' }}
        allowFullScreen='0'
        loading='lazy'
        referrerPolicy='no-referrer-when-downgrade'
        title='mapa'
        onLoad={() => setLoading(false)}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MenuFloat current={'view-360'} />
      {fetchIframeContent}
    </>
  );
};

export default View360;
