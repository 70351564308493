import GetEnv from './helpers/GetEnv';

const env = GetEnv('API_ENV');
const baseURL = GetEnv('API_URL_' + env);

const get = async (url) => {
  try {
    const response = await fetch(baseURL + url);
    return await response.json();
  } catch (error) {
    return console.error(error);
  }
};

const post = async (url, data) => {
  try {
    const response = await fetch(baseURL + url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return await response.json();
  } catch (error) {
    return console.error(error);
  }
};

export { get, post, baseURL };
