import { useContext, Fragment } from 'react';
import { Context } from '../Context';

// assets
import castle from '../assets/castle-icon.png';
import maletas from '../assets/maletas-icon.png';
import sea from '../assets/sea-icon.png';
import plane from '../assets/plane-icon.png';
import tel from '../assets/tel-icon.png';
import mail from '../assets/mail-icon.png';
import marker from '../assets/marker-icon.png';
import urlsite from '../assets/url-icon.png';
import valderrama from '../assets/logo-valderrama.png';
import alianza from '../assets/logo-alianza.png';
import bbva from '../assets/logo-bbva.png';

// components
import Form from './Form';
import Text from '../helpers/Text';

const isMobileDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Check for common keywords in the User Agent string
  const mobileKeywords = ['Android', 'webOS', 'iPhone', 'iPad', 'iPod', 'BlackBerry', 'IEMobile', 'Opera Mini'];

  return mobileKeywords.some((keyword) => userAgent.includes(keyword));
};

const generateLink = (url) => (
  <a href={url} target='_blank' rel='noreferrer'>
    {url}
  </a>
);

const generateTelLink = (url) => (
  <a href={`tel:${url.replace(/\s/g, '')}`} target='_blank' rel='noreferrer'>
    {url}
  </a>
);

const generateMailLink = (url) => (
  <a href={`mailto:${url}`} target='_blank' rel='noreferrer'>
    {url}
  </a>
);

const generateDevicesLink = (url) => {
  const isMobile = isMobileDevice();
  return isMobile ? (
    <a href={url.mobile} target='_blank' rel='noreferrer'>
      {url.label}
    </a>
  ) : (
    <a href={url.desktop} target='_blank' rel='noreferrer'>
      {url.label}
    </a>
  );
};

const generateUrlLink = (url) => (
  <a href={url.link} target='_blank' rel='noreferrer'>
    {url.label}
  </a>
);

const generateLinksWithSeparators = (linkArray, generateFunc) => {
  return linkArray.map((link, index) => (
    <Fragment key={index}>
      {index > 0 && ' - '}
      {generateFunc(link)}
    </Fragment>
  ));
};

const MapUrls = ({ urls, type }) => {
  if (!urls) return null;

  if (Array.isArray(urls)) {
    switch (type) {
      case 'tel':
        return generateLinksWithSeparators(urls, generateTelLink);
      case 'mail':
        return generateLinksWithSeparators(urls, generateMailLink);
      case 'devices':
        return generateLinksWithSeparators(urls, generateDevicesLink);
      case 'url':
        return generateLinksWithSeparators(urls, generateUrlLink);
      default:
        return generateLinksWithSeparators(urls, generateLink);
    }
  } else {
    switch (type) {
      case 'tel':
        return generateTelLink(urls);
      case 'mail':
        return generateMailLink(urls);
      case 'devices':
        return generateDevicesLink(urls);
      case 'url':
        return generateUrlLink(urls);
      default:
        return generateLink(urls);
    }
  }
};

const Contacto = () => {
  const { getInfoFromData } = useContext(Context);

  return (
    <div className='contacto'>
      <div className='contacto-top'>
        <div className='contacto-top__left'>
          <Form style={{ padding: 20, marginBottom: 40 }} />
        </div>
        <div className='contacto-top__right'>
          <div className='contacto-map'>
            <iframe
              title='map'
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9076.696233223793!2d-75.5127408182663!3d10.455597913364473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8ef62548fee6a283%3A0xf903eae6211a2687!2sCielo%20mar%2C%20La%20Boquilla%2C%20Provincia%20de%20Cartagena%2C%20Bol%C3%ADvar!5e0!3m2!1ses-419!2sco!4v1685663523022!5m2!1ses-419!2sco'
              className='contacto-map__iframe'
              allowFullScreen=''
              loading='lazy'
              referrerPolicy='no-referrer-when-downgrade'
            ></iframe>
          </div>
          <div className='contacto-icons'>
            <div className='contacto-icons__item'>
              <div className='contacto-icons__item_icon'>
                <img src={plane} alt='plane' />
              </div>
              <div className='contacto-icons__item_number'>
                <Text>{getInfoFromData('contacto_icon_1_number')}</Text>
              </div>
              <div className='contacto-icons__item_text'>
                <Text>{getInfoFromData('contacto_icon_1_text')}</Text>
              </div>
            </div>
            <div className='contacto-icons__item'>
              <div className='contacto-icons__item_icon'>
                <img src={sea} alt='sea' />
              </div>
              <div className='contacto-icons__item_number'>
                <Text>{getInfoFromData('contacto_icon_2_number')}</Text>
              </div>
              <div className='contacto-icons__item_text'>
                <Text>{getInfoFromData('contacto_icon_2_text')}</Text>
              </div>
            </div>
            <div className='contacto-icons__item'>
              <div className='contacto-icons__item_icon'>
                <img src={castle} alt='castle' />
              </div>
              <div className='contacto-icons__item_number'>
                <Text>{getInfoFromData('contacto_icon_3_number')}</Text>
              </div>
              <div className='contacto-icons__item_text'>
                <Text>{getInfoFromData('contacto_icon_3_text')}</Text>
              </div>
            </div>
            <div className='contacto-icons__item'>
              <div className='contacto-icons__item_icon'>
                <img src={maletas} alt='maletas' />
              </div>
              <div className='contacto-icons__item_number'>
                <Text>{getInfoFromData('contacto_icon_4_number')}</Text>
              </div>
              <div className='contacto-icons__item_text'>
                <Text>{getInfoFromData('contacto_icon_4_text')}</Text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='contacto-bottom'>
        <div className='contacto-bottom__left'>
          <a href={getInfoFromData('contacto_url_parent').link} target='_blank' rel='noreferrer'>
            <img src={valderrama} alt='valderrama' />
          </a>
        </div>
        <div className='contacto-bottom__center'>
          <div className='contacto-info'>
            <div className='contacto-info_item'>
              <img src={tel} alt='tel' />
              <MapUrls urls={getInfoFromData('contacto_tel_devices')} type='devices' />
            </div>
            <div className='contacto-info_item'>
              <img src={mail} alt='mail' />
              <MapUrls urls={getInfoFromData('contacto_mail')} type='mail' />
            </div>
            <div className='contacto-info_item'>
              <img src={marker} alt='marker' />
              {getInfoFromData('contacto_address')}
            </div>
            <div className='contacto-info_item'>
              <img src={urlsite} alt='urlsite' />
              <MapUrls urls={getInfoFromData('contacto_url_parent')} type='url' />
            </div>
          </div>
        </div>
        <div className='contact_bottom__right'>
          <div className='contact-financieros'>
            <div className='contact-financieros__item'>
              FIDUCIA: <br />
              <a href={getInfoFromData('contacto_fiducia_url')} target='_blank' rel='noreferrer'>
                <img src={alianza} alt='alianza' />
              </a>
            </div>
            <div className='contact-financieros__item'>
              CRÉDITO CONSTRUCCIÓN: <br />
              <a href={getInfoFromData('contacto_credito_url')} target='_blank' rel='noreferrer'>
                <img src={bbva} alt='bbva' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacto;
